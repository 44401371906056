export default function StickyMenu() {
    const domBlockMenuSticky = document.querySelector(".block-menu.sticky");

    if (!domBlockMenuSticky) {
        return;
    }

    window.addEventListener("scroll", OnWindowScroll, { passive: true });
    window.dispatchEvent(new Event("scroll"));

    function OnWindowScroll() {
        const domParent = domBlockMenuSticky.parentElement;

        const bbParent = domParent.getBoundingClientRect();
        domBlockMenuSticky.classList[bbParent.top < 0 ? "add" : "remove"]("not-top");
    }
}
