export default function Observer() {
    const pingAfter = 1000;

    let acts = [];

    if (!document.body) {
        window.addEventListener("load", run, { passive: true });
    } else {
        run();
    }

    let pingInterval;
    let prevPing = Date.now();

    function run() {
        window.removeEventListener("load", run);

        window.addEventListener("__ReloadElements__", ListenButtons, { passive: true });

        window.addEventListener("x-click", onWindowXClick, { passive: true });
        document.addEventListener("visibilitychange", onDocumentVisibilityChange, { passive: true });

        ListenButtons();

        add({ action: "visit", ref: document.referrer });
        beacon();

        setTimeout(() => ping(true), pingAfter);
    }

    function ListenButtons() {
        const domButtons = [...document.querySelectorAll("a.button"), ...document.querySelectorAll("button")];

        for (const domButton of domButtons) {
            if (domButton._observing_) {
                continue;
            }

            domButton._observing_ = true;

            if (domButton.getAttribute("ignoreconversion") !== null) {
                continue;
            }

            const isButton = domButton.tagName === "BUTTON" || domButton.classList.contains("button");
            const targetTagName = isButton ? "BUTTON" : domButton.tagName;

            domButton.addEventListener(
                "click",
                e => {
                    const eXClick = new Event("x-click");
                    eXClick._type_ = [
                        //
                        targetTagName,
                        domButton.href || domButton.name || "",
                        domButton?.textContent?.trim() || "",
                    ].join("; ");

                    window.dispatchEvent(eXClick);
                },
                { passive: true }
            );
        }
    }

    function onWindowXClick(e) {
        if (!e._type_) {
            return;
        }

        add({ action: "click", target: e._type_ });
        ping();
    }

    function onDocumentVisibilityChange() {
        if (document.visibilityState === "hidden") {
            clearInterval(pingInterval);
            pingInterval = null;
        } else {
            prevPing = Date.now();
            ping();
        }
    }

    function ping(force = false) {
        if (document.visibilityState === "hidden") {
            prevPing = Date.now();
        }

        if (document.timeline.currentTime >= pingAfter && (document.visibilityState === "visible" || force)) {
            const time = Math.max(0, Date.now() - prevPing);
            prevPing = Date.now();
            add({ action: "ping", time });
            beacon();
        }

        !pingInterval && (pingInterval = setInterval(() => ping(), 5000));
    }

    function add(action) {
        acts.push({ ct: Date.now(), ...action });
    }

    function beacon() {
        (navigator.sendBeacon ? _beacon : _img)(acts);
        acts = [];
    }

    function _img() {
        const domImg = document.createElement("img");
        domImg.style.setProperty("position", "fixed");
        domImg.style.setProperty("top", "-1px");
        domImg.style.setProperty("left", "-1px");
        domImg.style.setProperty("width", "0px");
        domImg.style.setProperty("height", "0px");
        domImg.style.setProperty("visibility", "hidden");
        domImg.style.setProperty("overflow", "hidden");

        /* domImg.addEventListener("load", event => {
            console.log("load", event);
        }, { passive: true });

        domImg.addEventListener("loadeddata", event => {
            console.log("loadeddata", event);
        }, { passive: true });

        domImg.addEventListener("loadedmetadata", event => {
            console.log("loadedmetadata", event);
        }, { passive: true });

        domImg.addEventListener("loadstart", event => {
            console.log("loadstart", event);
        }, { passive: true }); */

        domImg.setAttribute("alt", "");
        domImg.setAttribute("src", `/hit/${encodeURIComponent(JSON.stringify(acts))}`);

        document.body.appendChild(domImg);
    }

    function _beacon() {
        acts.length > 0 && navigator.sendBeacon(`/hit/?ct=${Date.now()}`, JSON.stringify(acts));
    }
}
