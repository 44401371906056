export default function BurgerMenu() {
    const domBurgerMenu = document.querySelector(".burger-menu");

    if (!domBurgerMenu) {
        console.warn("domBurgerMenu", domBurgerMenu);
        return;
    }

    const domBurgerMenuBtn = document.querySelector(".burger-menu-btn");

    domBurgerMenu.addEventListener("click", onShowBurgerMenuClick, { passive: true });
    domBurgerMenuBtn.addEventListener("click", onShowBurgerMenuClick, { passive: true });

    function onShowBurgerMenuClick() {
        const isShowing = domBurgerMenu.classList.contains("active");

        domBurgerMenu.classList[isShowing ? "remove" : "add"]("active");
        domBurgerMenuBtn.classList[isShowing ? "remove" : "add"]("active");
    }
}
