/**
 * For interactivity of page elements.
 * + Statistics
 */

import ArrowDown from "./handles/ArrowDown";
import BurgerMenu from "./handles/BurgerMenu";
import PlanSwipe from "./handles/PlanSwipe";
import ProductTypeImage from "./handles/ProductTypeImage";
import StickyMenu from "./handles/StickyMenu";

import Observer from "./handles/Observer";

Observer();

const CallList = [StickyMenu, ProductTypeImage, ArrowDown, BurgerMenu, PlanSwipe];

function Call() {
    for (const fn of CallList) {
        fn();
    }
}

window.addEventListener("DOMContentLoaded", Call, { passive: true });
window.addEventListener("__ReloadElements__", Call, { passive: true });
