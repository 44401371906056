import { ProductTypes } from "../../part-panel/lib/product-types";

export default function ProductTypeImage() {
    const { ProductType, ProductPicture } = window.__Variables__;

    // !TODO Remove if unused (FullWidthBg, EventHeader already inside variables)
    const {
        variables: { FullWidthBg },
    } = ProductTypes.filter(type => type.value === ProductType)?.[0];

    const domProductTypeImage = document.querySelector(".product-type-image");

    if (!domProductTypeImage) {
        return;
    }

    if (!ProductType) {
        return;
    }

    if (FullWidthBg) {
        return;
    }

    let svgColor;
    {
        const currentColor = getComputedStyle(domProductTypeImage).color;

        const [_, r, g, b] = currentColor.match(/(\d{1,3}), ?(\d{1,3}), ?(\d{1,3})/) || [];

        if (_) {
            svgColor = [r, g, b].join("") === "000" ? "" : "-white";
        }
    }

    if (ProductType === "Custom") {
        const blocks = window.__Variables__.variables2block;

        for (const item of blocks) {
            const {
                variables: { ProductType: CustomType, ProductPicture: CustomTypePictures },
            } = item;

            if (item.name === "BlockHeader") {
                fetch(`/img/product-types/${CustomType}${svgColor}.svg`)
                    .then(r => {
                        return r.status === 200 ? r.text() : null;
                    })
                    .then(textXml => {
                        if (!textXml) {
                            (window.logs = window.logs || []).push([`[ProductTypeImage] Svg is:`, svg]);
                            return;
                        }

                        const element = document.querySelector(`[data-element-id='${item.id}']`);
                        const domCustomTypeImage = element.querySelector(".product-type-image");

                        for (const child of domCustomTypeImage.children) {
                            child.parentElement.removeChild(child);
                        }

                        let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                        svg.innerHTML = textXml;
                        svg = svg.firstChild;

                        domCustomTypeImage.appendChild(svg);

                        const images = svg.querySelectorAll(".svg-product-type-image");

                        if (!CustomTypePictures) {
                            return;
                        } else {
                            const retina = window.devicePixelRatio > 1;
                            images.forEach((image, index) => {
                                const pp = CustomTypePictures[index];
                                const href = (retina && pp?.retina) || pp?.normal || pp?.src || pp?.file?.dataUrlBase64;

                                if (!CustomTypePictures[index]) {
                                    image.removeAttribute("href");
                                } else {
                                    image.setAttribute("href", href);
                                }
                            });
                        }
                    })
                    .catch(e => {});
            }
        }

        return;
    }

    fetch(`/img/product-types/${ProductType}${svgColor}.svg`)
        .then(r => r.text())
        .then(textXml => {
            for (const child of domProductTypeImage.children) {
                child.parentElement.removeChild(child);
            }

            let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
            svg.innerHTML = textXml;
            svg = svg.firstChild;
            domProductTypeImage.appendChild(svg);

            const images = svg.querySelectorAll(".svg-product-type-image");

            if (!ProductPicture) {
                return;
            } else {
                const retina = window.devicePixelRatio > 1;
                images.forEach((image, index) => {
                    const pp = ProductPicture[index];
                    const href = (retina && pp?.retina) || pp?.normal || pp?.src || pp?.file?.dataUrlBase64;

                    image.setAttribute("href", href);

                    if (!ProductPicture[index]) {
                        image.removeAttribute("href");
                    }
                });
            }
        });
}
