export default function ArrowDown() {
    const { blocks } = window.__Variables__;
    const anchor = blocks && blocks[1].split("-").join("");

    let domDownContainer = [...document.querySelectorAll(".down-container")];

    if (!domDownContainer || domDownContainer.length === 0) {
        console.warn("domDownContainer", domDownContainer);
        return;
    }

    for (const dom of domDownContainer.slice(1)) {
        dom.parentElement && dom.parentElement.removeChild(dom);
    }
    domDownContainer = domDownContainer[0];

    const domDownGo = domDownContainer.querySelector(".down-go");
    domDownGo.addEventListener("click", () => (window.location = `#${anchor}`), { passive: true });

    window.addEventListener("scroll", OnWindowScroll, { passive: true });
    window.dispatchEvent(new Event("scroll"));

    function OnWindowScroll() {
        domDownContainer.classList[window.scrollY > 0 ? "add" : "remove"]("down-hide");
    }
}
