export default function PlanSwipe() {
    const { Offers } = window.__Variables__;

    if (!Offers || Offers.length === 0) {
        return;
    }

    // To set childrens lenght
    const domPlans = document.querySelector(".plan");
    if (!domPlans) {
        return;
    }

    const N = Offers.length;
    domPlans.style.setProperty("--n", N);

    domPlans.addEventListener("mousedown", lock, { passive: true });
    domPlans.addEventListener("touchstart", lock, { passive: true });

    domPlans.addEventListener("mouseup", move, { passive: true });
    domPlans.addEventListener("touchend", move, { passive: true });

    // domPlans.addEventListener("mousemove", drag, false);
    // domPlans.addEventListener("touchmove", drag, false);

    //  Initial coordinate
    let x0 = null;

    let locked = false;

    // To detect touch or mouse move
    function unify(e) {
        return e.changedTouches ? e.changedTouches[0] : e;
    }

    // Touchstart / MouseDown
    function lock(e) {
        x0 = unify(e).clientX;
        domPlans.classList.toggle("smooth", !(locked = true));
    }

    // TouchEnd / MouseUp
    let i = 0;

    function move(e) {
        if (!locked) {
            return;
        }
        let dx = unify(e).clientX - x0,
            s = Math.sign(dx);

        if (Math.abs(dx) < 50) {
            return;
        }

        if ((i > 0 || s < 0) && (i < N - 1 || s > 0)) domPlans.style.setProperty("--i", (i -= s));
        domPlans.style.setProperty("--tx", "0px");
        domPlans.classList.toggle("smooth", !(locked = false));
        x0 = null;
    }

    // TouchMove / MouseMove
    // function drag(e) {
    //     if (locked) {
    //         return;
    //     }
    //     //e.preventDefault()
    //     if (x0 || x0 === 0) domPlans.style.setProperty("--tx", `${Math.round(unify(e).clientX - x0)}px`);
    // }
}
