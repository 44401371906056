/**
 * images - /public/img/product-types-fill
 */

const ProductTypes = [
    {
        // Mobile
        name_t: "ProductTypeMobileApp",
        value: "mobile-app",
        images: ["/img/product-types-fill/mobile-2.webp", "/img/product-types-fill/mobile.webp"],
        variables: {
            FullWidthBg: false,
            EventHeader: false,
        },
    },

    {
        // Web
        name_t: "ProductTypeWebProduct",
        value: "web-product",
        images: ["/img/product-types-fill/laptop.webp"],
        variables: {
            FullWidthBg: false,
            EventHeader: false,
        },
    },

    {
        // Cross
        name_t: "ProductTypeCrossPlatformProduct",
        value: "cross-platform",
        images: [
            "/img/product-types-fill/tablet.webp",
            "/img/product-types-fill/laptop.webp",
            "/img/product-types-fill/mobile.webp",
        ],
        variables: {
            FullWidthBg: false,
            EventHeader: false,
        },
    },

    // TODO! Disabled by https://pedai.atlassian.net/browse/LPB-487?atlOrigin=eyJpIjoiNTNhMmM5ZWFiOWIzNDM2ZmFhYjg5YmQzYzE2MzkwNDYiLCJwIjoiaiJ9
    /* {
        // eCommerce
        name_t: "eCommerce",
        value: "ecommerce",
        images: ["/img/product-types-fill/ecommerce.webp"],
    }, */

    {
        // Travel & Adventure
        name_t: "ProductTypeAdventureProduct",
        value: "services",
        images: [
            `/img/product-types-fill/travel-adventure/travel-adventure-1.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-2.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-3.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-4.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-5.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-6.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-7.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-8.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-9.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-10.webp`,
        ],
        variables: {
            FullWidthBg: true,
            EventHeader: false,
        },
    },
    {
        // Event
        name_t: "ProductTypeEventProduct",
        value: "event",
        images: [
            `/img/product-types-fill/travel-adventure/travel-adventure-1.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-2.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-3.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-4.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-5.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-6.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-7.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-8.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-9.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-10.webp`,
        ],
        variables: {
            FullWidthBg: true,
            EventHeader: true,
        },
    },
    {
        // Edutainment
        name_t: "ProductTypeEdutainmentProduct",
        value: "edutainment",
        images: [
            `/img/product-types-fill/travel-adventure/travel-adventure-1.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-2.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-3.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-4.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-5.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-6.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-7.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-8.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-9.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-10.webp`,
        ],
        variables: {
            FullWidthBg: true,
            EventHeader: true,
        },
    },
    {
        // Custom
        name_t: "ProductTypeCustom",
        value: "Custom",
        images: [
            `/img/product-types-fill/travel-adventure/travel-adventure-1.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-2.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-3.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-4.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-5.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-6.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-7.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-8.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-9.webp`,
            `/img/product-types-fill/travel-adventure/travel-adventure-10.webp`,
        ],
        variables: {
            FullWidthBg: false,
            EventHeader: false,
        },
    },
];

const ProductTypeByValue = {};

for (const type of ProductTypes) {
    ProductTypeByValue[type.value] = type;
}

export { ProductTypes, ProductTypeByValue };
